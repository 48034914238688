





























































































































































































































































.user {
  display: flex;
  flex-direction: column;

  &__form-wrapper {
    overflow-y: auto;
  }

  &__form {
    padding: 24px 24px 4px;
    border-bottom: 1px solid #e7e7e7;
  }

  &__roles {
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__form,
  &__roles {
    border-right: 1px solid #e7e7e7;
  }

  &__footer {
    padding: 16px 32px;
    border-top: 1px solid #e7e7e7;
    background-color: #fafafa;
    text-align: right;
    margin-top: auto;
  }
}

.role {
  flex-basis: calc(50% - 16px);
}

@media screen and (max-width: 768px) {
  .role {
    flex-basis: 100%;
  }
}

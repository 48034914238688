<template>
  <label class="checkbox-container"><slot /></label>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckboxContainer extends Vue {}
</script>

<style scoped>
.checkbox-container {
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
}
</style>
